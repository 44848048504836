import { Paper } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Page from "components/Common/Page";
import { useNavigate } from "react-router-dom";
import useConferencesList from "./ConferencesList.hooks";
import CreateConferenceDialog from "./CreateConferenceDialog";

const columns: GridColDef[] = [
  { field: "name", headerName: "Name", width: 260 },
  { field: "deleted", headerName: "Deleted" },
  { field: "createdAt", headerName: "Created At", width: 180 },
  { field: "updatedAt", headerName: "Updated At", width: 180 },
];

export default function ConferencesListPage() {
  const navigate = useNavigate();
  const {
    conferences,
    fetchConferences,
    page,
    loading,
    onPageChange,
    limit,
    setLimit,
  } = useConferencesList();

  return (
    <Page
      heading="Conferences"
      RightHeadingComponent={
        <CreateConferenceDialog
          onCreate={() => {
            fetchConferences(page);
          }}
        />
      }
    >
      <Paper sx={{ width: "100%" }}>
        <DataGrid
          sx={{
            "--DataGrid-overlayHeight": "300px",
            ".MuiDataGrid-cell": {
              cursor: "pointer",
            },
          }}
          autoHeight
          columns={columns}
          rows={conferences}
          loading={loading}
          pageSize={limit}
          onPageChange={(page) => onPageChange(page)}
          onPageSizeChange={(pageSize) => setLimit(pageSize)}
          onCellClick={({ id }) => navigate(`/conferences/${id}`)}
        ></DataGrid>
      </Paper>
    </Page>
  );
}
