import { getConference, updateConference } from "api/conferences";
import { Conference } from "models/Conference";
import { useCallback, useEffect, useState } from "react";

export default function useConference(id: string) {
  const [conference, setConference] = useState<Conference>();
  const [loading, setLoading] = useState(true);

  const update = useCallback(
    (values: Partial<Conference>) => {
      const observable = updateConference(id, values);

      observable.subscribe(({ data }) => {
        setConference(data);
      });

      return observable;
    },
    [id, conference]
  );

  useEffect(() => {
    getConference(id).subscribe(({ data }) => {
      setLoading(false);
      setConference(data);
    });
  }, []);

  return {
    loading,
    conference,
    update,
  };
}
