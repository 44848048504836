import {
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { createConference } from "api/conferences";
import useForm from "components/Form/Form.hooks";
import DialogModal from "components/Modals/DialogModal";

interface Props {
  onCreate: () => void;
}
const TITLE = "Create Conference";

export default function CreateConferenceDialog({ onCreate }: Props) {
  const { handleFormChange, formValues } = useForm({});
  const { name } = formValues;

  return (
    <DialogModal
      renderDisplay={(_, setOpen) => {
        return (
          <Button variant="contained" onClick={() => setOpen(true)}>
            {TITLE}
          </Button>
        );
      }}
      renderDialog={(_, setOpen) => {
        return (
          <Box minWidth={400}>
            <Card>
              <DialogTitle>{TITLE}</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Name"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={name || ""}
                  name="name"
                  onChange={handleFormChange}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    createConference(name).subscribe(() => {
                      setOpen(false);
                      onCreate();
                    });
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </Card>
          </Box>
        );
      }}
      onClose={() => {}}
    />
  );
}
