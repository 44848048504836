import { Conference } from 'models/Conference';
import { get, post } from './base';

export const getConferences = (limit?: number, offset?: number) => {
  return get('/admin/conferences', { limit: limit, offset: offset });
};

export const getConference = (id: string) => {
  return get(`/admin/conference/${id}`);
};

export const createConference = (name: string) => {
  return post('/admin/conferences', true, { name });
};

export const updateConference = (id: string, fields: Partial<Conference>) => {
  return post(`/admin/conference/${id}`, true, { ...fields });
};
