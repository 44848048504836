import { getConferences } from "api/conferences";
import { Conference } from "models/Conference";
import { useCallback, useEffect } from "react";
import { useState } from "react";

interface Conferences {
  total: number;
  value: Conference[];
}

const useConferencesList = () => {
  const [limit, setLimit] = useState(25);
  const [loading, setLoading] = useState<boolean>(false);
  const [conferences, setConferences] = useState<Conferences>({
    total: 0,
    value: [],
  });
  const [page, setPage] = useState<number>(0);

  const fetchConferences = useCallback((offset: number) => {
    setLoading(true);

    getConferences(limit, offset).subscribe(({ data }) => {
      setLoading(false);

      setConferences({
        ...data,
        value: data.conferences,
      });
    });
  }, []);

  useEffect(() => {
    const offset = page * limit;
    fetchConferences(offset);
  }, [page, limit, fetchConferences]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  return {
    conferences: conferences.value,
    loading,
    fetchConferences,
    page,
    onPageChange,
    conferencesCount: conferences.total,
    limit,
    setLimit,
  };
};

export default useConferencesList;
