import Page from "components/Common/Page";
import { ReactNode } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Box } from "@mui/system";
import { Button, Paper } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { bool } from "aws-sdk/clients/signer";

interface EditPageProps {
  heading?: string;
  children?: ReactNode;
  onClear: () => void;
  onSave: () => void;
  updateInProgress: bool;
}

export default function EditPage({
  children,
  heading,
  onClear,
  onSave,
  updateInProgress,
}: EditPageProps) {
  return (
    <Page heading={heading}>
      <Grid2 container spacing={2} rowSpacing={2} sx={{ textAlign: "left" }}>
        <Grid2 xs={6}>
          <Paper sx={{ padding: "20px" }}>{children}</Paper>
          <Grid2
            container
            justifyContent="space-between"
            sx={{ paddingTop: "20px" }}
          >
            <Box>
              <Button
                variant="outlined"
                sx={{ marginRight: "8px" }}
                onClick={onClear}
              >
                Clear
              </Button>

              <LoadingButton
                variant="contained"
                loadingPosition="start"
                startIcon={<SaveIcon />}
                loading={updateInProgress}
                onClick={onSave}
              >
                Save
              </LoadingButton>
            </Box>
          </Grid2>
        </Grid2>
      </Grid2>
    </Page>
  );
}
