import { Box } from "@mui/system";
import Page from "components/Common/Page";
import { Route, Routes, useParams } from "react-router-dom";
import useConference from "./Conference.hooks";
import ConferenceDetailsPage from "./ConferenceDetailsPage";
import ConferencesListPage from "./ConferencesListPage";

const ConferencePage = () => {
  const { id } = useParams();
  const { loading, conference, update } = useConference(id as string);

  if (loading) {
    return (
      <Page>
        <Box>Loading...</Box>
      </Page>
    );
  }

  if (!conference) {
    return (
      <Page>
        <Box>Conference Not Found</Box>
      </Page>
    );
  }

  return <ConferenceDetailsPage conference={conference} update={update} />;
};

export default function ConferenceRoutes() {
  return (
    <Routes>
      <Route key="conferences" path="/" element={<ConferencesListPage />} />
      <Route
        key="conference-details"
        path="/:id"
        element={<ConferencePage />}
      ></Route>
    </Routes>
  );
}
