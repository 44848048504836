import {
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { AxiosResponse } from "axios";
import useForm from "components/Form/Form.hooks";
import { Conference } from "models/Conference";
import { useEffect, useState } from "react";
import { Observable } from "rxjs";
import EditPage from "components/Pages/EditPage";

interface Props {
  conference: Conference;
  update: (
    conference: Partial<Conference>
  ) => Observable<AxiosResponse<any, any>>;
}

export default function ConferenceDetailsPage({ conference, update }: Props) {
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const { formValues, handleFormChange, setForm, setFormValues } =
    useForm(conference);

  useEffect(() => {
    setFormValues(conference);
  }, [conference]);

  return (
    <EditPage
      updateInProgress={updateInProgress}
      heading={`Conference "${conference.name}"`}
      onClear={() => {
        setFormValues(conference);
      }}
      onSave={() => {
        setUpdateInProgress(true);
        update(formValues).subscribe(() => {
          setUpdateInProgress(false);
        });
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "16px",
        }}
      >
        <TextField
          id="name"
          type="text"
          name="name"
          label="Conference Name"
          variant="standard"
          value={formValues?.name || ""}
          onChange={handleFormChange}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formValues.deleted}
              name="deleted"
              onChange={(e) => {
                const { checked } = e.target;

                setForm("deleted", checked);
              }}
            />
          }
          label="Deleted"
        />
        <Typography>Created At: {formValues.createdAt}</Typography>
        <Typography>Updated At: {formValues.updatedAt}</Typography>
      </Box>
    </EditPage>
  );
}
